import React from "react";
import MyGaugeChart from "./GaugeChart";
import GaugeChart2 from "./GaugeChart2";
import WavyLineChart from "./WavyLineChart";
import BarChart from "./BarChart";
import SearchComponent from "./SearchComponent";
import UsersProgress from '../assets/users_progress.svg';
import Clicks from '../assets/clicks-progress.svg';
import Referral from '../assets/referral_progress.svg';
import Projects from '../assets/project_progress.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserPlus,
  faUsers,
  faFolder,
  faRocket,
  faCartPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

function TopData({ username }) {
  return (
    <div className="mt-48 md:mt-0">
       <div className="flex flex-row md:justify-end justify-center">
          <div>
             <SearchComponent/>
          </div>
       </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">

      

        <div className="flex  items-center justify-center h-24 rounded">
          <div className="bg-gradient-to-br from-[#060B26CC] to-transparent mx-2 p-4 tube flex flex-row justify-between mb-4 md:mb-0">
            <div>
              <p className="text-slate-400 text-sm">Today's Users</p>
              <div className="flex flex-row justify-between">
                <p className="px-2">300</p>
                <span className="text-green-500 text-sm px-2">+5%</span>
              </div>
            </div>
            <div className="flag">
              <FontAwesomeIcon icon={faUser} className="px-2" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center h-24 rounded">
          <div className="bg-gradient-to-br from-[#060B26CC] to-transparent mx-2 p-4 tube flex flex-row justify-between mb-4 md:mb-0">
            <div>
              <p className="text-slate-400 text-sm">New Users</p>
              <div className="flex flex-row justify-between">
                <p className="px-2">52</p>
                <span className="text-red-700 text-base px-2">-14%</span>
              </div>
            </div>
            <div className="flag">
              <FontAwesomeIcon icon={faUserPlus} className="px-2" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center h-24 rounded">
          <div className="bg-gradient-to-br from-[#060B26CC] to-transparent mx-2 p-4 tube flex flex-row justify-between mb-4 md:mb-0  ">
            <div>
              <p className="text-slate-400 text-sm">Total Users</p>
              <div className="flex flex-row justify-between">
                <p className="px-2">700</p>
                <span className="text-green-500 text-base px-2">+8%</span>
              </div>
            </div>

            <div className="flag">
              <FontAwesomeIcon icon={faUsers} className="px-2" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-4">
        <div className="flex-1 rounded-3xl">
          <div className="welcome_card px-10 py-4 rounded-3xl w-full">
            <p className="text-start text-xs text-slate-500 py-4">
              Welcome Back,
            </p>
            <p className="text-start text-2xl">{username}</p>
            <p className="text-start text-xs text-slate-500 py-4">
              Glad to see you again
            </p>
          </div>
        </div>

        <div className="gauge-container w-full h-full">
          <MyGaugeChart />
        </div>
        <div className="gauge-container w-full h-full">
          <GaugeChart2 />
        </div>
      </div>

      

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-4  p-6 rounded w-full">
        <div>
        <div className="flex flex-row justify-between my-4 container">
        <div className="mx-6 bg-custom-gradient rounded px-4 py-2">
          <p className="text-xs">Weekly Analysis</p>
        </div>
        <div className="mx-16">
          <p className="rounded px-4 py-2 bg-custom-gradient text-xs">Last Week</p>
        </div>
      </div>

      <div className="gauge-container mx-auto mb-8 px-6 py-2 w-full h-auto bg-custom-gradient rounded-lg">
          <BarChart />

          <div>
            <p className="text-white text-sm">Active Users</p>
            <p className="text-slate-500 text-xs">
              <span className="text-green-500">(+23)</span> than last week
            </p>
          </div>

          <div className="grid grid-cols-2 md:flex md:flex-row justify-between my-16 container">
            <div className="users mr-4">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faFolder}
                  className="bg-blue-700 p-1 text-xs rounded"
                />
                <p className="text-xs p-1 text-slate-500">Users</p>
              </div>
              <div>
                <p className="text-base p-1">32,984</p>
                <img src={UsersProgress} alt='number of users'/>
              </div>
            </div>

            <div className="clicks mr-4">
              <div className="flex">
              <FontAwesomeIcon icon={faRocket} className="bg-blue-700 p-1 text-xs rounded"/>
              <p className="text-xs p-1 text-slate-500">Clicks</p>
              </div>
              <div>
                <p className="text-base p-1">2.42m</p>
                <img src={Clicks} alt='number of clicks'/>
              </div>
            </div>

            <div className="Referrals mr-4">
              <div className="flex">
              <FontAwesomeIcon icon={faCartPlus} className="bg-blue-700 p-1 text-xs rounded"/>
              <p className="text-xs p-1 text-slate-500">Referrals</p>
              </div>

              <div>
                <p className="text-base p-1">2,400</p>
                <img src={Referral} alt="number of referrals"/>
              </div>
            </div>

            <div className="Total Projects ">
               <div className="flex">
               <FontAwesomeIcon icon={faWrench} className="bg-blue-700 p-1 text-xs rounded"/>
               <p className="text-xs p-1 text-slate-500">Projects</p>
               </div>

               <div>
                  <p className="text-base p-1">320</p>
                  <img src={Projects} alt="number of projects"/>
               </div>
            </div>
          </div>
        </div>
        </div>
        

        <div className="gauge-container mx-auto mb-8 px-6 py-2 w-full h-auto bg-custom-gradient rounded-lg">
           <div className="container">
               <div className="flex flex-row justify-between my-4 container">
                    <div className="mx-6  rounded px-4 py-2">
                     <p className="text-xs">Users Overview</p>
                </div>
      
               </div>

              <WavyLineChart/>
           </div>
        </div>
      </div>
    </div>
  );
}

export default TopData;
