import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchComponent = () => {
  // Sample data representing words found on the admin dashboard
  const dashboardWords = [
    'User  Management',
    'Settings',
    'Reports',
    'Analytics',
    'Dashboard',
    'Notifications',
    'Profile',
    'Help',
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState('');

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setResult('sorry, match not found');
    } else {
      const foundWord = dashboardWords.find(word => word.toLowerCase() === searchTerm.toLowerCase());
      setResult(foundWord ? foundWord : 'sorry, match not found');
    }
  };

  return (
    <div className="search-component p-1 flex flex-row text-xs w-2/4">
        <button onClick={handleSearch} className="search-button text-slate-500 text-base bg-transparent p-2 mx-2 rounded-lg"><FontAwesomeIcon icon={faSearchengin} /></button>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Type here..."
        className="search-input rounded bg-transparent border-slate-500 border-solid p-1 text-xs text-slate-400"
      />
      
      <div className="search-result text-slate-500 text-xs p-4 flex flex-row justify-center">{result}</div>
    </div>
  );
};

export default SearchComponent;