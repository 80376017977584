import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/sidebar.css';
import Qol from '../assets/qol_logo.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTasks, faBullhorn, faHouseChimney } from '@fortawesome/free-solid-svg-icons'; 

function Sidebar() {
    // const adminName = localStorage.getItem('name');
    // const adminEmail = localStorage.getItem('email');
    const [activeIndex, setActiveIndex] = useState(0);

    const menuItems = [
      { icon: faHouseChimney, label: 'Dashboard' },
      { icon: faChartLine, label: 'Analytics' },
      { icon: faTasks, label: 'Tasks' },
      { icon: faBullhorn, label: 'Campaigns' },
    ];


  return (
    <aside className="sidebar z-10 fixed top-0 left-0 w-full bg-custom-gradient md:bg-gradient-to-br from-[#060B26CC] to-transparent md:w-64 md:h-screen  opacity-100  md:fixed">
      <div className="sidebarArea">
            {/* Sidebar content */}
            
            <Link to='/'>
              <div className='flex flex-row justify-center'>
              <img src={Qol} alt="logo" id='logo' className="w-40"/>
              </div>
            
            </Link>
            <hr className='my-2'/>
            <ul className="menu flex justify-around md:flex-col md:items-start md:p-0 p-2">
      {menuItems.map((item, index) => (
        <li
          key={index}
          className={`flex justify-center items-center p-1 md:p-4 ${activeIndex === index ? 'letter' : ''}`}
          onClick={() => setActiveIndex(index)} // Set active index on click
        >
          <FontAwesomeIcon icon={item.icon} className='bag text-lg transition-transform duration-300 transform hover:scale-110' />
          &nbsp; 
          <span className='hidden md:inline-block p-4'>{item.label}</span>
        </li>
      ))}
    </ul>
             
        </div>
        
    
    </aside>
  );
}

export default Sidebar;