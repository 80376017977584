import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State for Remember Me
  const defaultPassword = 'Qol_admin123'; // Default password for all admins
  const navigate = useNavigate();


  // Load saved credentials if Remember Me was checked
  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    const savedPassword = localStorage.getItem('rememberedPassword');
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const storedPassword = localStorage.getItem(username) || defaultPassword;

    if (password === storedPassword) {
      localStorage.setItem('loggedInUser', username); // Store logged-in user
      console.log('Username stored in localStorage:', username);
      // onLogin(username); // Pass username to parent component
    if (rememberMe) {
      localStorage.setItem('rememberedUsername', username);
      localStorage.setItem('rememberedPassword', password);
    } else {
      localStorage.removeItem('rememberedUsername');
      localStorage.removeItem('rememberedPassword');
    }


      navigate('/custom/admin'); //Navigate to dashboard
    } else {
      setError('Invalid username or password');
    }
  };

   // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

 

  return (
    <div className='bg-transparent font-[sans-serif]'>
      <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
        <div className='max-w-md w-full'>
          <div className='p-8 rounded-2xl bg-blue-700 shadow'>
          <h2 className="text-white text-center text-2xl font-bold">Sign In</h2>
          <form onSubmit={handleLogin} className="flex flex-column mt-8 space-y-4">
            <div>
            <label class="text-gray-100 text-sm mb-2 block">User name</label>
               <div className='relative flex items-center'>
               <input
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full text-gray-800 text-sm border border-gray-300 px-12 py-3 rounded-md outline-blue-600 bg-slate-200"
          required
           />
                             <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-4 h-4 absolute right-4" viewBox="0 0 24 24">
                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                    <path d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z" data-original="#000000"></path>
                  </svg>

               </div>
            </div>

            <div>
            <label class="text-gray-100 text-sm mb-2 block">Password</label>
            <div className='relative flex items-center'>
            <input
          type= {showPassword? "text" : "password"} // Toggle between text and password
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full text-gray-800 text-sm border border-gray-300 px-12 py-3 rounded-md outline-blue-600"
          required
        />
           <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-4 h-4 absolute right-4 cursor-pointer" viewBox="0 0 128 128" onClick={togglePasswordVisibility}>
                    <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" data-original="#000000"></path>
                  </svg>
            </div>
            </div>
           
        
        {error && <p className="text-gray-100 text-sm mb-2 block">{error}</p>}

        <div class="flex flex-wrap items-center justify-between gap-4">
                <div class="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 shrink-0 text-slate-600 focus:bg-slate-700 border-black rounded-lg" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} /> 
                  <label for="remember-me" class="ml-3 block text-sm text-gray-800">
                    Remember me
                  </label>
                </div>
                
              </div>

        <div className='!mt-8'>
        <button type="submit" className="button w-full py-3 px-8 text-sm tracking-wide rounded-lg text-white bg-slate-800 hover:bg-slate-600 focus:outline-none">
          Login
        </button>
        </div>
        
      </form>
          </div>

            </div>
           
    </div>
    </div>





  );
};

export default Login;