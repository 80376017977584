import React from 'react';
import Sidebar from './Sidebar';
import TopData from '../components/TopData';
import '../styles/admin.css';

function Admin() {
  const username = localStorage.getItem('loggedInUser') || 'Guest';

  return (
    <>
    <div className='flex'>
       <Sidebar /> {/* sidebar component */}
        <div className="p-4 md:ml-64 flex-1 admin_portal">
         
          <div className=''>
             <TopData username={username}/>
          </div>
            {/* Main content area */}
         
        </div>
</div>
   
   
    </>
  );
}

export default Admin;