import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  // Sample data for the bar chart
  const data = {
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    datasets: [
      {
        label: '',
        data: [310, 220, 100, 300, 500, 420, 280, 180, 70, 145, 45, 82],
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'transparent',
        borderWidth: 1, // Border width for the bars
        barThickness: 5, // Set the thickness of the bars (slimmer)
        borderRadius: 10,
       
      },
    ],
  };

  // Options for the bar chart
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
      title: {
        display: false,
        text: 'Monthly Sales Data',
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines on the x-axis
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
        min: 0, // Set minimum value for y-axis
        max: 500, // Set maximum value for y-axis
        ticks: {
          stepSize: 50, // Set the step size for y-axis ticks
          color: 'rgba(255, 255, 255, 1)',
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Change grid line color
        },
      },
    },
  };

  return (
    <div className="p-8 w-full">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;