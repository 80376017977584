import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import AlternativeLayout from './components/AlternativeLayout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Delete from './pages/Delete';
import Login from './components/Login';
import Admin from './pages/Admin';

import "./index.css"







const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path='/' element= {<Layout/>}>
      <Route index element= {<Home/>} />
      {/* <Route path='about' element= {<About/>} /> */}
      <Route path='blog' element= {<Blog/>} />
      <Route path='contact' element= {<Contact/>} />
      <Route path='privacy' element= {<Privacy/>} />
      <Route path='terms' element= {<Terms/>} />
      <Route path='delete' element= {<Delete/>} />
      <Route path='login' element= {<Login/>} />
      {/* <Route path='admin' element= {<Admin/>} /> */}

     
    </Route>
    
    {/* <Route path="/custom/login" element={<AlternativeLayout><Login/></AlternativeLayout>} /> */}
      <Route path="/custom/admin" element={<AlternativeLayout><Admin/></AlternativeLayout>} />
  </Routes>
  </BrowserRouter>
);


