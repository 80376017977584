import React from 'react';
// import RadialChart from 'react-gauge-chart';
import RadialChart from 'react-apexcharts'

// const MyGaugeChart = () => {
//   return (
//     <div>
//       <p className='text-sm p-4 text-start'>Referral Tracking <br/> <span className='text-slate-500 text-xs text-start'>From all projects</span></p>
      
//       <GaugeChart 
//         id="gauge-chart1"
//         nrOfLevels={6}
//         percent={0.95} // Set the percentage value here
//         arcWidth={0.1}
//         textColor={"#0075FF"}
//         colors={["#0075FF", "rgba(0, 117, 255, 0)"]}
//       />
//       <div>
//         <div className='text-center text-base'></div>
//         <div className='text-center text-sm text-slate-500'>Based on signups</div>
//       </div>
//     </div>
//   );
// };

const MyGaugeChart = () => {
  const [state] = React.useState({
    series: [65],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
        }
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
          track: {
            background: 'transparent', // This makes the unfilled part transparent
          },
          dataLabels: {
            name: {
              fontSize: '22px',
              color: '#FFF', 
              offsetY: -10,
            },
            value: {
              fontSize: '16px',
              color: '#D3D3D3', 
              offsetY: 10,
            },
          },
        },
      },
      labels: ['Sign Ups'],
      
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        
        y: {
          formatter: (value) => {
            return `Sign Ups: ${value}`; // Customize the tooltip label here
          },
        },
      },
    },
  
  
});



  return (
    <div>
      <div id="chart">
        <RadialChart options={state.options} series={state.series} type="radialBar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}



export default MyGaugeChart;