// WavyLineChart.js
import React from 'react';
import Chart from 'react-apexcharts';





const WavyLineChart = () => {
  const [state] = React.useState({
    
      series: [{
        name: 'Sign Ups',
        data: [500, 250, 200, 240, 180, 220, 300, 350, 280, 240, 360, 400],
        
      }, {
        name: 'Users',
        data: [180, 200, 250, 300, 350, 220, 200, 270, 320, 350, 330, 280]
      }],
      options: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'month',
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
          labels: {
            style: {
              colors: '#fff', // Change this to your desired color for X-axis markers
            },
          },
        },
        tooltip: {
          x: {
            format: 'MM/yy'
          },
        },
      },
    
    
  });

  

  return (
    <div>
      <div id="chart">
          <Chart options={state.options} series={state.series} type="area" height={350} />
        </div>
      <div id="html-dist"></div>
    </div>
  );
}



export default WavyLineChart;