import React from 'react';
// import Login from '../pages/Login';
import Admin from '../pages/Admin';

const AlternativeLayout = ({ children }) => {
    return (
        <div>
            {/* Custom header for this layout */}
            <header className="bg-gradient-to-br from-[#060B26CC] to-transparent">
                
            </header>
            <div>
                {/* <Login/> */}
                <Admin/>
            </div>
            {/* Different footer can be added here */}
        </div>
    );
};

export default AlternativeLayout;