import React from 'react';
import GaugeChart from 'react-gauge-chart';

const GaugeChart2 = () => {
  return (
    <div className="gauge-chart-container">
        <p className='text-sm p-4 text-start'>User Tracking <br/> <span className='text-slate-500 text-xs text-start'>Active/Inactive</span></p>
        

      <GaugeChart 
        id="gauge-chart2"
        nrOfLevels={6} // Single smooth arc
        percent={0.9} // Set a different percentage value for the second chart
        arcWidth={0.1} // Adjust the arc width as needed
        textColor={"#05CD99"}
        colors={["#05CD99", "rgba(5, 205, 153, 0)"]} // Different colors for the second gauge
      />


<div className='flex flex-row justify-between p-8'>
<div className='text-center  bg-gradient-to-br from-[#060B26CC] to-transparent rounded'>
        <div className='text-sm text-slate-500'>Active</div>
        <div className='text-sm'>145 users</div>
      </div>
      <div className='text-center bg-gradient-to-br from-[#060B26CC] to-transparent rounded'>
        <div className='text-sm text-slate-500'>Inactive</div>
        <div className='text-sm'>45 users</div>
      </div>
</div>
      
    </div>
  );
};

export default GaugeChart2;